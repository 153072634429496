import React, { useState, useEffect } from 'react';
import { 
  Card, CardContent, Button, Typography, TextField, Alert, AlertTitle, 
  LinearProgress, Box, Container, ThemeProvider, createTheme 
} from '@mui/material';
import { styled } from '@mui/system';

// Assumiamo che phishingEmails sia importato da un altro file
import { phishingEmails } from './phishingEmails';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

const StyledCard = styled(Card)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(4),
  padding: theme.spacing(3),
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
}));

const EmailContent = styled(Box)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const ResultAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const EnhancedPhishingQuiz = () => {
  const [userData, setUserData] = useState({ name: '', email: '' });
  const [quizStarted, setQuizStarted] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [score, setScore] = useState(0);
  const [weightedScore, setWeightedScore] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [finalJudgment, setFinalJudgment] = useState(null);
  const [answerStatus, setAnswerStatus] = useState(null);
  const [shuffledEmails, setShuffledEmails] = useState([]);

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    };

    const randomizedEmails = shuffleArray([...phishingEmails]);
    setShuffledEmails(randomizedEmails);
  }, []);

  const startQuiz = () => {
    if (userData.name && userData.email) {
      setQuizStarted(true);
    } else {
      alert('Per favore, inserisci il tuo nome e la tua email per iniziare il quiz.');
    }
  };

  const handleAnswer = (isPhishingGuess) => {
    const currentEmail = shuffledEmails[currentQuestionIndex];
    const isCorrect = isPhishingGuess === currentEmail.isPhishing;
    setAnswerStatus(isCorrect ? 'correct' : 'incorrect');
    if (isCorrect) {
      const pointsEarned = getPointsForDifficulty(currentEmail.difficulty);
      setScore(score + 1);
      setWeightedScore(weightedScore + pointsEarned);
    }
    setShowResult(true);
  };

  const getPointsForDifficulty = (difficulty) => {
    switch (difficulty) {
      case 'facile': return 1;
      case 'medio': return 2;
      case 'difficile': return 3;
      default: return 1;
    }
  };

  const nextQuestion = () => {
    if (currentQuestionIndex < shuffledEmails.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowResult(false);
      setAnswerStatus(null);
    } else {
      setQuizCompleted(true);
      setFinalJudgment(getFinalJudgment(weightedScore, shuffledEmails.length));
    }
  };

  const getFinalJudgment = (score, total) => {
    const percentage = (score / total) * 100;
    if (percentage <= 50) {
      return {
        judgment: "Necessiti di miglioramento significativo",
        suggestions: [
          "Studia attentamente i segnali comuni delle email di phishing",
          "Fai pratica con più esempi di email di phishing",
          "Considera di seguire un corso di formazione sulla sicurezza informatica"
        ]
      };
    } else if (percentage <= 80) {
      return {
        judgment: "Hai una buona conoscenza di base, ma c'è spazio per migliorare",
        suggestions: [
          "Concentrati sull'identificazione degli URL sospetti",
          "Fai attenzione alle tattiche di ingegneria sociale nelle email",
          "Esercitati con esempi più complessi di phishing"
        ]
      };
    } else {
      return {
        judgment: "Eccellente! Hai una solida comprensione del phishing",
        suggestions: [
          "Mantieni aggiornate le tue conoscenze sulle ultime minacce di phishing",
          "Considera di diventare un punto di riferimento per la sicurezza informatica nel tuo ambiente",
          "Esplora tecniche avanzate di sicurezza delle email"
        ]
      };
    }
  };

  const formatExplanation = (explanation) => {
    // Divide l'explanation in paragrafi
    const paragraphs = explanation.split('\n\n');
    
    return paragraphs.map((paragraph, index) => {
      // Controlla se il paragrafo contiene punti numerati
      if (paragraph.includes('1.') && paragraph.includes('2.')) {
        // Divide il paragrafo in punti
        const points = paragraph.split(/(\d+\.\s)/).filter(Boolean);
        
        return (
          <Box key={index} mt={2}>
            {points.map((point, pointIndex) => {
              if (/^\d+\.\s/.test(point)) {
                // Se è un numero seguito da un punto, renderizzalo come un punto dell'elenco
                return (
                  <Box key={pointIndex} mt={1} display="flex">
                    <Typography variant="body1" style={{ minWidth: '25px' }}>{point}</Typography>
                    <Typography variant="body1">{points[pointIndex + 1]}</Typography>
                  </Box>
                );
              } else if (!points[pointIndex - 1] || !/^\d+\.\s/.test(points[pointIndex - 1])) {
                // Se non è preceduto da un numero, renderizzalo come testo normale
                return <Typography key={pointIndex} variant="body1">{point}</Typography>;
              }
              // Se è il contenuto di un punto numerato, lo saltiamo perché è già stato renderizzato
              return null;
            })}
          </Box>
        );
      } else {
        // Se non contiene punti numerati, renderizza come paragrafo normale
        return <Typography key={index} variant="body1" paragraph>{paragraph}</Typography>;
      }
    });
  };

  const renderEmailContent = () => {
    const email = shuffledEmails[currentQuestionIndex];
    return (
      <EmailContent>
        <Typography variant="body1" gutterBottom><strong>Da:</strong> {email.sender}</Typography>
        <Typography variant="body1" gutterBottom><strong>Oggetto:</strong> {email.subject}</Typography>
        <Box mt={2}>
          <div dangerouslySetInnerHTML={{ 
            __html: email.content(userData).replace(
              /<a href="#" data-fake-url="([^"]+)">([^<]+)<\/a>/g, 
              (match, fakeUrl, displayText) => 
                `<span style="color: blue; text-decoration: underline; cursor: pointer;" title="${fakeUrl}">${displayText}</span>`
            )
          }} />
        </Box>
      </EmailContent>
    );
  };

  const renderQuizContent = () => {
    const email = shuffledEmails[currentQuestionIndex];
    return (
      <StyledCard>
        <CardContent>
          <Typography variant="h5" gutterBottom>Email {currentQuestionIndex + 1} di {shuffledEmails.length}</Typography>
          <Typography variant="subtitle1" gutterBottom>Difficoltà: {email.difficulty}</Typography>
          {renderEmailContent()}
          {!showResult ? (
            <Box mt={3} display="flex" justifyContent="center">
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={() => handleAnswer(true)} 
                style={{ marginRight: '16px' }}
                size="large"
              >
                Phishing
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={() => handleAnswer(false)}
                size="large"
              >
                Legittima
              </Button>
            </Box>
          ) : (
            <Box mt={3}>
              <ResultAlert severity={answerStatus === 'correct' ? "success" : "error"}>
                <AlertTitle>
                  <Typography variant="h6">
                    {answerStatus === 'correct' ? 'Risposta corretta!' : 'Risposta sbagliata!'}
                  </Typography>
                </AlertTitle>
                <Typography variant="body1">
                  {email.isPhishing ? "Era phishing!" : "Era legittima!"}
                </Typography>
                <Box mt={2}>
                  {formatExplanation(email.explanation)}
                </Box>
              </ResultAlert>
              <Box mt={3} display="flex" justifyContent="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={nextQuestion}
                  size="large"
                >
                  {currentQuestionIndex < shuffledEmails.length - 1 ? 'Prossima domanda' : 'Completa il quiz'}
                </Button>
              </Box>
            </Box>
          )}
        </CardContent>
      </StyledCard>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        {!quizStarted ? (
          <StyledCard>
            <CardContent>
              <Typography variant="h4" gutterBottom>Quiz di Consapevolezza sul Phishing</Typography>
              <Typography variant="body1" paragraph>
                Metti alla prova la tua capacità di riconoscere le email di phishing. Inserisci il tuo nome e email per iniziare.
              </Typography>
              <TextField
                label="Nome"
                value={userData.name}
                onChange={(e) => setUserData({...userData, name: e.target.value})}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <TextField
                label="Email"
                type="email"
                value={userData.email}
                onChange={(e) => setUserData({...userData, email: e.target.value})}
                fullWidth
                margin="normal"
                variant="outlined"
              />
              <Box mt={3} display="flex" justifyContent="center">
                <Button 
                  variant="contained" 
                  color="primary" 
                  onClick={startQuiz} 
                  size="large"
                >
                  Inizia il Quiz
                </Button>
              </Box>
            </CardContent>
          </StyledCard>
        ) : !quizCompleted ? (
          renderQuizContent()
        ) : (
          <StyledCard>
            <CardContent>
              <Typography variant="h4" gutterBottom>Quiz Completato!</Typography>
              <Typography variant="h6" gutterBottom>Punteggio finale: {score} / {shuffledEmails.length}</Typography>
              <Typography variant="body1" gutterBottom>
                Punteggio ponderato: {weightedScore} / {shuffledEmails.reduce((acc, email) => acc + getPointsForDifficulty(email.difficulty), 0)}
              </Typography>
              <Box my={2}>
                <LinearProgress 
                  variant="determinate"
                  value={(weightedScore / shuffledEmails.reduce((acc, email) => acc + getPointsForDifficulty(email.difficulty), 0)) * 100} 
                />
              </Box>
              <Typography variant="body1" gutterBottom>Risposte corrette: {score}</Typography>
              <Typography variant="body1" gutterBottom>Risposte sbagliate: {shuffledEmails.length - score}</Typography>
              <Box mt={3}>
                <Typography variant="h5" gutterBottom>{finalJudgment.judgment}</Typography>
                <Typography variant="body1" gutterBottom>Suggerimenti per migliorare:</Typography>
                <ul>
                  {finalJudgment.suggestions.map((suggestion, index) => (
                    <li key={index}><Typography variant="body1">{suggestion}</Typography></li>
                  ))}
                </ul>
              </Box>
            </CardContent>
          </StyledCard>
        )}
      </Container>
    </ThemeProvider>
  );
};

export default EnhancedPhishingQuiz;
