export const phishingEmails = [
{
    difficulty: "facile",
    subject: "Conferma il tuo indirizzo email per il tuo account Amazon",
    sender: "no-reply@amazon.it",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Grazie per aver creato un account Amazon. Per completare la registrazione e garantire la sicurezza del tuo account, ti chiediamo di confermare il tuo indirizzo email.<br><br>
      <a href="#" data-fake-url="https://www.amazon.it/gp/css/homepage.html/ref=pe_1234567_123456789">Conferma il tuo indirizzo email</a><br><br>
      Se non hai creato un account Amazon, puoi ignorare questa email.<br><br>
      Nota: questo link di conferma scadrà tra 24 ore per motivi di sicurezza.<br><br>
      Cordiali saluti,<br>
      Amazon.it
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di conferma da Amazon. L'indirizzo del mittente è corretto (no-reply@amazon.it), 
      l'URL del link corrisponde al dominio ufficiale di Amazon (www.amazon.it), e l'email non richiede 
      informazioni sensibili. La presenza di un limite di tempo per la conferma è una pratica di sicurezza comune.
    `
  },
  {
    difficulty: "facile",
    subject: "Azione richiesta: Aggiorna le tue informazioni bancarie",
    sender: "servizio.clienti@intesasanpaolo.com",
    content: (userData) => `
      Gentile Cliente,<br><br>
      La informiamo che è necessario aggiornare le Sue informazioni per continuare a utilizzare i servizi di online banking.<br><br>
      Per procedere con l'aggiornamento, la preghiamo di cliccare sul seguente link:<br>
      <a href="#" data-fake-url="http://intesasanpaolo-aggiornamento.com/verifica">Aggiorna le tue informazioni</a><br><br>
      IMPORTANTE: Se non effettuerà l'aggiornamento entro 48 ore, il Suo accesso ai servizi online potrebbe essere temporaneamente sospeso per motivi di sicurezza.<br><br>
      Cordiali saluti,<br>
      Servizio Clienti<br>
      Intesa Sanpaolo S.p.A.
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita una comunicazione di Intesa Sanpaolo. Nonostante l'aspetto professionale, 
      ci sono diversi segnali d'allarme: l'URL del link non corrisponde al dominio ufficiale della banca, 
      viene creato un senso di urgenza ingiustificato, e si minaccia la sospensione del servizio. Le banche 
      legittime non chiedono mai di aggiornare le informazioni tramite link nelle email.
    `
  },
  {
    difficulty: "facile",
    subject: "Il tuo pacco è in attesa di ritiro - Azione richiesta",
    sender: "noreply@posteitaliane.it",
    content: (userData) => `
      Gentile Cliente,<br><br>
      La informiamo che è in giacenza presso il nostro centro di smistamento un pacco a Lei destinato.<br>
      Non è stato possibile effettuare la consegna a causa di un indirizzo incompleto.<br><br>
      Per programmare una nuova consegna o il ritiro presso un ufficio postale, la preghiamo di visitare il seguente link:<br>
      <a href="#" data-fake-url="https://www.poste.it/tracking.tracking.html?item=IT1234567890">Gestisci la tua spedizione</a><br><br>
      Codice spedizione: IT1234567890<br>
      Nota: Se non verrà programmata una nuova consegna entro 5 giorni lavorativi, il pacco sarà restituito al mittente.<br><br>
      Cordiali saluti,<br>
      Poste Italiane S.p.A.
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita Poste Italiane. Nonostante l'aspetto professionale e l'uso di un 
      codice di spedizione plausibile, l'URL del link non corrisponde al dominio ufficiale di Poste Italiane. 
      Inoltre, crea un senso di urgenza che potrebbe spingere l'utente ad agire frettolosamente.
    `
  },
  {
    difficulty: "facile",
    subject: "Conferma della tua prenotazione #1234567",
    sender: "noreply@booking.com",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      La tua prenotazione presso Hotel Bellavista è confermata.<br><br>
      Dettagli della prenotazione:<br>
      - Numero di prenotazione: 1234567<br>
      - Data di check-in: 15 luglio 2024, dalle 14:00<br>
      - Data di check-out: 20 luglio 2024, entro le 11:00<br>
      - Numero di ospiti: 2 adulti<br>
      - Tipo di camera: Camera Doppia Vista Mare<br><br>
      Per visualizzare o modificare la tua prenotazione, visita:<br>
      <a href="#" data-fake-url="https://www.booking.com/reservations.it.html?bn=1234567&pincode=1234">Gestisci prenotazione</a><br><br>
      Ti auguriamo un piacevole soggiorno!<br>
      Il team Booking.com
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di conferma da Booking.com. L'indirizzo del mittente è corretto, 
      l'URL del link corrisponde al dominio ufficiale di Booking.com, e fornisce informazioni dettagliate 
      sulla prenotazione, incluso un numero di prenotazione specifico. Non richiede azioni urgenti o 
      informazioni sensibili.
    `
  },
  {
    difficulty: "facile",
    subject: "Avviso di Sicurezza: Accesso non autorizzato rilevato",
    sender: "no-reply@id.apple.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Abbiamo rilevato un tentativo di accesso non autorizzato al tuo ID Apple.<br><br>
      Dettagli dell'accesso:<br>
      Data: ${new Date().toLocaleDateString()}<br>
      Ora: ${new Date().toLocaleTimeString()}<br>
      Posizione: Mosca, Russia<br><br>
      Se sei stato tu, puoi ignorare questa email. In caso contrario, ti consigliamo di modificare immediatamente la tua password:<br>
      <a href="#" data-fake-url="https://appleid.apple.com/password/reset">Reimposta la tua password</a><br><br>
      Per ulteriore assistenza, visita il nostro Centro Assistenza Apple ID.<br><br>
      Cordiali saluti,<br>
      Il team Sicurezza Apple
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di sicurezza da Apple. L'indirizzo del mittente (no-reply@id.apple.com) 
      è corretto, l'URL del link corrisponde al dominio ufficiale di Apple per la gestione degli ID Apple. 
      L'email fornisce dettagli specifici sull'accesso sospetto e non richiede di inserire informazioni 
      sensibili direttamente nella email.
    `
  },
  {
    difficulty: "facile",
    subject: "Conferma la tua iscrizione alla newsletter de La Repubblica",
    sender: "newsletter@rep.repubblica.it",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Grazie per aver richiesto l'iscrizione alla newsletter de La Repubblica.<br>
      Per completare l'iscrizione e iniziare a ricevere le ultime notizie, conferma il tuo indirizzo email cliccando sul seguente link:<br><br>
      <a href="#" data-fake-url="https://login.repubblica.it/newsletter/confirm?token=abc123def456">Conferma iscrizione</a><br><br>
      Se non hai richiesto questa iscrizione, puoi semplicemente ignorare questa email.<br><br>
      Per qualsiasi domanda, consulta le nostre <a href="#" data-fake-url="https://www.repubblica.it/static/servizi/gerenza.html">FAQ</a> o contattaci all'indirizzo assistenza@repubblica.it.<br><br>
      Cordiali saluti,<br>
      La redazione de La Repubblica
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di conferma per l'iscrizione a una newsletter. Il mittente è corretto 
      (un sottodominio di repubblica.it), l'URL del link di conferma corrisponde al dominio ufficiale del 
      giornale, e non viene richiesta alcuna informazione sensibile. L'email fornisce anche un contatto 
      per l'assistenza, che è una buona pratica.
    `
  },
  {
    difficulty: "facile",
    subject: "Azione richiesta: Verifica il tuo account PayPal",
    sender: "service@paypal.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Abbiamo notato alcune attività insolite sul tuo account PayPal. Per garantire la sicurezza del tuo conto, abbiamo temporaneamente limitato alcune funzionalità.<br><br>
      Per rimuovere queste limitazioni, verifica la tua identità cliccando sul seguente link:<br>
      <a href="#" data-fake-url="http://secure-paypal-verify.com/account">Verifica il tuo account</a><br><br>
      Se non completi la verifica entro 48 ore, potremmo essere costretti a sospendere temporaneamente il tuo account per motivi di sicurezza.<br><br>
      Se hai domande, contatta il nostro servizio clienti al numero 800 123 456.<br><br>
      Cordiali saluti,<br>
      Il team PayPal
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita PayPal. Nonostante l'aspetto professionale e l'indirizzo 
      del mittente che sembra autentico, ci sono diversi segnali d'allarme: l'URL del link non corrisponde 
      al dominio ufficiale di PayPal (paypal.com), viene creato un senso di urgenza, e si minaccia la 
      sospensione dell'account. PayPal non chiede mai di verificare l'account tramite link nelle email.
    `
  },
  {
    difficulty: "facile",
    subject: "Il tuo ordine Zalando #987654321 è stato spedito!",
    sender: "noreply@zalando.it",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      Ottime notizie! Il tuo ordine è stato spedito ed è in viaggio verso di te.<br><br>
      Dettagli dell'ordine:<br>
      - Numero ordine: 987654321<br>
      - Data di spedizione: ${new Date().toLocaleDateString()}<br>
      - Metodo di spedizione: Corriere espresso<br><br>
      Puoi tracciare il tuo pacco qui:<br>
      <a href="#" data-fake-url="https://www.zalando.it/myaccount/order/987654321">Traccia il tuo ordine</a><br><br>
      Grazie per aver scelto Zalando!<br>
      Il team Zalando
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di conferma di spedizione da Zalando. L'indirizzo del mittente è corretto, 
      l'URL del link corrisponde al dominio ufficiale di Zalando, e fornisce dettagli specifici sull'ordine 
      senza richiedere informazioni sensibili. La presenza di un numero d'ordine e di una data di spedizione 
      specifica aumenta la credibilità dell'email.
    `
  },
  {
    difficulty: "facile",
    subject: "Aggiorna il tuo metodo di pagamento - Netflix",
    sender: "info@mailer.netflix.com",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      Stiamo riscontrando problemi con il tuo attuale metodo di pagamento.<br>
      Per continuare a goderti Netflix senza interruzioni, aggiorna le tue informazioni di pagamento.<br><br>
      Aggiorna qui:<br>
      <a href="#" data-fake-url="http://netflix-account-update.com/payment">Aggiorna metodo di pagamento</a><br><br>
      Se non aggiorni entro 48 ore, il tuo account potrebbe essere temporaneamente sospeso.<br><br>
      Per assistenza, visita il nostro <a href="#" data-fake-url="http://netflix-account-update.com/help">Centro Assistenza</a>.<br><br>
      Il team Netflix
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita Netflix. Ci sono diversi segnali d'allarme:
      1. L'URL del link per l'aggiornamento del pagamento non corrisponde al dominio ufficiale di Netflix.
      2. Viene creato un senso di urgenza ingiustificato.
      3. Si minaccia la sospensione dell'account.
      4. Anche il link del Centro Assistenza è sospetto.
      Netflix non chiede mai di aggiornare le informazioni di pagamento tramite link nelle email.
    `
  },
  {
    difficulty: "facile",
    subject: "Aggiornamento di sicurezza per il tuo account LinkedIn",
    sender: "security-noreply@linkedin.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Come parte del nostro impegno continuo per mantenere la sicurezza dei nostri utenti, ti chiediamo di aggiornare la password del tuo account LinkedIn.<br><br>
      Questo è un processo di routine che aiuta a proteggere i tuoi dati e a mantenere il tuo account al sicuro.<br><br>
      Per aggiornare la tua password, segui questi passaggi:<br>
      1. Visita la pagina di accesso di LinkedIn: <a href="#" data-fake-url="https://www.linkedin.com/login">Accedi a LinkedIn</a><br>
      2. Clicca su "Password dimenticata?"<br>
      3. Segui le istruzioni per reimpostare la tua password<br><br>
      Se non puoi accedere al link sopra, vai direttamente su LinkedIn.com e cerca l'opzione "Password dimenticata" nella pagina di accesso.<br><br>
      Ricorda: LinkedIn non ti chiederà mai di fornire la tua password via email.<br><br>
      Grazie per aiutarci a mantenere LinkedIn un ambiente sicuro per tutti i professionisti.<br><br>
      Il team di sicurezza LinkedIn
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di LinkedIn per un aggiornamento di sicurezza. Ecco perché:
      1. L'indirizzo del mittente è corretto (security-noreply@linkedin.com).
      2. Non fornisce un link diretto per cambiare la password, ma indirizza l'utente alla pagina di accesso ufficiale.
      3. Fornisce istruzioni chiare su come reimpostare la password in modo sicuro.
      4. Include un promemoria importante che LinkedIn non chiede mai le password via email.
      5. Non crea un senso di urgenza ingiustificato.
      6. Il linguaggio e il tono sono professionali e coerenti con le comunicazioni di LinkedIn.
    `
  }
  ,
  {
    difficulty: "medio",
    subject: "Aggiornamento urgente delle credenziali aziendali richiesto",
    sender: "it-security@acme-global.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      A seguito di un recente audit di sicurezza, stiamo implementando nuove misure di protezione per tutti gli account aziendali. È necessario aggiornare le tue credenziali entro le prossime 48 ore.<br><br>
      Per procedere con l'aggiornamento, accedi al portale di sicurezza aziendale:<br>
      <a href="#" data-fake-url="https://security-portal.acme-global.net/update-credentials">Aggiorna le tue credenziali</a><br><br>
      Nota: Se non aggiorni le tue credenziali entro il termine stabilito, l'accesso al tuo account potrebbe essere temporaneamente sospeso per motivi di sicurezza.<br><br>
      Per assistenza, contatta l'help desk IT al numero interno 5555.<br><br>
      Cordiali saluti,<br>
      Marco Bianchi<br>
      Responsabile IT Security<br>
      ACME Global Corporation
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing sofisticata. Nonostante l'aspetto professionale, ci sono diversi segnali d'allarme:
      1. L'URL del link non corrisponde esattamente al dominio aziendale menzionato nell'indirizzo email del mittente (acme-global.net vs acme-global.com).
      2. Crea un senso di urgenza, una tattica comune nelle email di phishing.
      3. Minaccia la sospensione dell'account, un altro metodo per spingere l'utente ad agire frettolosamente.
      Le aziende legittime generalmente non richiedono aggiornamenti di credenziali tramite email con link diretti.
    `
  },
  {
    difficulty: "medio",
    subject: "Avviso di Sicurezza: Accesso non autorizzato al tuo Apple ID",
    sender: "no_reply@id.apple.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Abbiamo rilevato un tentativo di accesso non autorizzato al tuo Apple ID (${userData.email}) da un dispositivo non riconosciuto situato a San Paolo, Brasile.<br><br>
      Dettagli dell'accesso:<br>
      Data: ${new Date().toLocaleDateString()}<br>
      Ora: ${new Date().toLocaleTimeString()}<br>
      Posizione: San Paolo, Brasile<br>
      Dispositivo: iPhone 12<br><br>
      Se non sei stato tu, il tuo account potrebbe essere stato compromesso. Ti consigliamo di verificare immediatamente la tua attività recente e aggiornare le tue impostazioni di sicurezza:<br>
      <a href="#" data-fake-url="https://appleid.apple.com.verify-login.net/account/manage">Verifica la sicurezza del tuo account</a><br><br>
      Se non prendi provvedimenti entro 24 ore, potremmo dover limitare temporaneamente l'accesso al tuo account per proteggerlo.<br><br>
      Se hai bisogno di assistenza, visita il nostro Centro Assistenza Apple ID.<br><br>
      Cordiali saluti,<br>
      Apple Account Security Team
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita Apple. Ci sono diversi segnali d'allarme:
      1. Sebbene l'indirizzo del mittente sembri autentico, l'URL per la verifica non corrisponde al dominio ufficiale di Apple (appleid.apple.com).
      2. L'uso di un sottodominio ingannevole (apple.com.verify-login.net) è una tattica comune di phishing.
      3. Crea un senso di urgenza con la minaccia di limitare l'accesso all'account.
      4. Fornisce dettagli specifici per sembrare più credibile, ma questo è un trucco comune dei phisher.
      Apple non invia mai link diretti per la verifica dell'account nelle email.
    `
  },
  {
    difficulty: "medio",
    subject: "Allarme Sicurezza: Transazione sospetta rilevata sul tuo conto",
    sender: "sicurezza@intesasanpaolo.com",
    content: (userData) => `
      Gentile Cliente ${userData.name},<br><br>
      Abbiamo rilevato una transazione potenzialmente fraudolenta sul tuo conto corrente:<br><br>
      Data: ${new Date().toLocaleDateString()}<br>
      Ora: ${new Date().toLocaleTimeString()}<br>
      Importo: €1.275,00<br>
      Beneficiario: Tech Global Store<br>
      Tipo di transazione: Acquisto online<br><br>
      Se non riconosci questa transazione, ti preghiamo di accedere immediatamente al tuo Internet Banking per bloccarla e verificare i movimenti del tuo conto:<br>
      <a href="#" data-fake-url="https://www.intesasanpaolo.com.secure-login.eu/login">Accedi a Internet Banking</a><br><br>
      Per assistenza immediata, contatta il nostro servizio antifrode al numero verde 800 123 456, attivo 24 ore su 24.<br><br>
      Ricorda: Intesa Sanpaolo non ti chiederà mai di fornire password o codici di sicurezza tramite email o telefono.<br><br>
      Cordiali saluti,<br>
      Intesa Sanpaolo - Servizio Sicurezza e Antifrode
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing sofisticata che imita una comunicazione bancaria. Ecco perché è sospetta:
      1. Nonostante l'apparenza professionale, l'URL per l'accesso non corrisponde al dominio ufficiale della banca.
      2. L'uso di un dominio simile ma non identico (intesasanpaolo.com.secure-login.eu invece di intesasanpaolo.com) è un chiaro segnale di phishing.
      3. Crea un senso di urgenza riguardo a una transazione sospetta per indurre l'utente ad agire rapidamente.
      4. Fornisce un numero di telefono, ma in una situazione reale, dovresti sempre usare i numeri ufficiali trovati sul sito web della banca, non quelli forniti in un'email sospetta.
    `
  },
  {
    difficulty: "medio",
    subject: "Azione richiesta: Aggiorna i tuoi dati per evitare la sospensione dell'account Netflix",
    sender: "info@mailer.netflix.com",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      Abbiamo riscontrato che alcune informazioni del tuo account Netflix non sono aggiornate, il che potrebbe compromettere la continuità del servizio.<br><br>
      Per garantirti un'esperienza ottimale e evitare interruzioni, ti chiediamo gentilmente di aggiornare i tuoi dati il prima possibile.<br><br>
      Aggiorna le tue informazioni qui:<br>
      <a href="#" data-fake-url="https://accounts.netflix.com.update-info.net/manage-account">Aggiorna i tuoi dati</a><br><br>
      Importante: Se non aggiorni le tue informazioni entro i prossimi 7 giorni, potremmo essere costretti a limitare temporaneamente l'accesso al tuo account per motivi di sicurezza.<br><br>
      Se hai domande o hai bisogno di assistenza, visita il nostro Centro Assistenza o contattaci direttamente.<br><br>
      Grazie per la tua collaborazione nel mantenere il tuo account sicuro e aggiornato.<br><br>
      Il Team Netflix
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita Netflix. Ecco i segnali d'allarme:
      1. Sebbene l'indirizzo del mittente possa sembrare legittimo, l'URL per l'aggiornamento dei dati non è il dominio ufficiale di Netflix.
      2. L'uso di un dominio simile ma falso (netflix.com.update-info.net) è una tattica comune di phishing.
      3. Crea un senso di urgenza minacciando la limitazione dell'account.
      4. Netflix non richiede mai aggiornamenti di informazioni personali tramite email con link diretti.
      5. L'email tenta di sembrare legittima offrendo assistenza, ma questo è un trucco comune nei tentativi di phishing.
    `
  },
  {
    difficulty: "medio",
    subject: "Richiesta di assistenza urgente e confidenziale",
    sender: "mario.rossi@aziendapartner.it",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Sono Mario Rossi, CFO di AziendaPartner. Mi trovo in una situazione delicata e ho urgente bisogno della tua assistenza per una questione finanziaria riservata.<br><br>
      Sto lavorando a un progetto confidenziale che richiede un trasferimento di fondi immediato. Per motivi di massima riservatezza, non posso fornire ulteriori dettagli via email aziendale.<br><br>
      Ti prego di contattarmi al mio indirizzo email personale quanto prima: mariorossi.cfo@gmail.com<br><br>
      È di vitale importanza mantenere la massima discrezione su questa richiesta. Non discuterne con nessun altro in azienda.<br><br>
      Confido nella tua collaborazione e discrezione.<br><br>
      Cordiali saluti,<br>
      Mario Rossi<br>
      Chief Financial Officer<br>
      AziendaPartner S.p.A.
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che utilizza la tecnica del "CEO fraud" o, in questo caso, "CFO fraud". Ecco i segnali d'allarme:
      1. L'uso di un indirizzo email personale (Gmail) per questioni aziendali è altamente sospetto.
      2. La richiesta di effettuare un trasferimento di fondi urgente e confidenziale è un classico scenario di frode.
      3. L'enfasi sulla riservatezza e la richiesta di non discutere la questione con altri sono tattiche per isolare la vittima.
      4. La mancanza di dettagli specifici sul progetto è un altro segnale d'allarme.
      5. Un vero CFO non chiederebbe mai a un dipendente di gestire trasferimenti di fondi in questo modo, bypassando le normali procedure aziendali.
    `
  },
  {
    difficulty: "medio",
    subject: "URGENTE: Aggiornamento di sicurezza critico per il tuo dispositivo Apple",
    sender: "updates@apple.com",
    content: (userData) => `
      Gentile utente Apple ${userData.name},<br><br>
      Il nostro sistema ha rilevato una vulnerabilità di sicurezza critica che potrebbe compromettere seriamente la sicurezza del tuo dispositivo iOS e dei tuoi dati personali.<br><br>
      È assolutamente essenziale che tu installi immediatamente l'ultimo aggiornamento di sicurezza per proteggere il tuo dispositivo. Puoi farlo direttamente da qui:<br>
      <a href="#" data-fake-url="https://updates.apple-devices.com/security-patch">Installa aggiornamento di sicurezza</a><br><br>
      Dettagli dell'aggiornamento:<br>
      - Versione: iOS 15.5.1<br>
      - Dimensione: 1.2 GB<br>
      - Criticità: Alta<br><br>
      ATTENZIONE: La mancata installazione di questo aggiornamento critico potrebbe esporre i tuoi dati personali, inclusi dettagli finanziari e di accesso, a gravi rischi di sicurezza.<br><br>
      Se hai domande o dubbi, visita il nostro Centro Assistenza Apple ufficiale.<br><br>
      Cordiali saluti,<br>
      Team Sicurezza Apple
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing che imita un avviso di sicurezza di Apple. Ecco perché è sospetta:
      1. Nonostante l'apparenza urgente e ufficiale, l'URL per l'aggiornamento non corrisponde al dominio ufficiale di Apple.
      2. Apple non distribuisce mai aggiornamenti di sicurezza tramite link diretti nelle email.
      3. L'email crea un senso di panico e urgenza, una tattica comune di phishing.
      4. Fornisce dettagli specifici sull'aggiornamento per sembrare più credibile, ma questo è un trucco comune dei phisher.
      5. Gli aggiornamenti iOS vengono sempre notificati e gestiti direttamente sul dispositivo, non via email.
    `
  },
  {
    difficulty: "medio",
    subject: "Importante: Aggiornamento della Privacy Policy di Google",
    sender: "noreply@accounts.google.com",
    content: (userData) => `
      Gentile utente Google,<br><br>
      Ti informiamo che abbiamo apportato modifiche importanti alla nostra Privacy Policy per migliorare ulteriormente la protezione dei tuoi dati e allinearci alle nuove normative internazionali sulla privacy.<br><br>
      Principali modifiche:<br>
      1. Maggiore controllo sui dati condivisi con le app di terze parti<br>
      2. Nuove opzioni per la gestione della cronologia delle attività<br>
      3. Aggiornamento delle politiche di conservazione dei dati<br><br>
      Ti invitiamo a rivedere attentamente le modifiche e confermare la tua accettazione entro i prossimi 30 giorni:<br>
      <a href="#" data-fake-url="https://myaccount.google.com/privacypolicy/update">Rivedi e accetta la nuova Privacy Policy</a><br><br>
      Nota: Se non accetti le modifiche entro il termine indicato, alcune funzionalità del tuo account Google potrebbero essere limitate per garantire la conformità alle nuove normative.<br><br>
      Per domande o chiarimenti, consulta il nostro Centro assistenza o contattaci direttamente.<br><br>
      Grazie per la tua collaborazione nel mantenere la sicurezza e la privacy del tuo account Google.<br><br>
      Il team Google
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email da Google. Ecco perché:
      1. L'indirizzo del mittente è corretto (noreply@accounts.google.com).
      2. L'URL del link corrisponde al dominio ufficiale di Google (myaccount.google.com).
      3. Il contenuto è informativo e non crea un senso di urgenza ingiustificato.
      4. Google invia regolarmente notifiche per aggiornamenti importanti delle sue policy.
      5. L'email fornisce dettagli sulle modifiche senza richiedere informazioni sensibili.
      6. Il linguaggio e il tono sono professionali e coerenti con le comunicazioni di Google.
    `
  },
  {
    difficulty: "medio",
    subject: "Conferma la tua presenza al TechInnovation Summit 2024",
    sender: "events@techinnovation.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Siamo lieti di ricordarti che il nostro evento annuale "TechInnovation Summit 2024" si terrà il 15-16 settembre presso il Centro Congressi Internazionale.<br><br>
      Per confermare la tua partecipazione e selezionare le sessioni a cui desideri assistere, ti preghiamo di compilare il seguente modulo entro il 31 luglio:<br>
      <a href="#" data-fake-url="https://events.techinnovation.com/summit2024/rsvp?emp=${userData.email.split('@')[0]}">Conferma partecipazione</a><br><br>
      Programma dell'evento:<br>
      - Keynote di apertura: "Il Futuro dell'IA nelle Imprese"<br>
      - Workshops tematici su Blockchain, IoT, e Cybersecurity<br>
      - Networking lunch e sessioni di Q&A con esperti del settore<br><br>
      La tua partecipazione è importante per noi. Se hai domande o necessiti di assistenza per la registrazione, non esitare a contattare il team organizzativo all'indirizzo summit2024@techinnovation.com.<br><br>
      Non vediamo l'ora di accoglierti all'evento!<br><br>
      Cordiali saluti,<br>
      Il Comitato Organizzatore<br>
      TechInnovation Summit 2024
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email aziendale. Ecco perché:
      1. L'indirizzo del mittente corrisponde al dominio aziendale.
      2. L'URL del link è coerente con il dominio dell'azienda.
      3. Non viene richiesta alcuna informazione sensibile.
      4. Il contesto dell'evento annuale è plausibile e dettagliato.
      5. Fornisce informazioni specifiche sull'evento e un contatto per eventuali domande.
      6. Non crea un senso di urgenza ingiustificato.
    `
  },
  {
    difficulty: "medio",
    subject: "Avviso di sicurezza: Nuovo accesso al tuo account Microsoft",
    sender: "account-security-noreply@accountprotection.microsoft.com",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      Abbiamo rilevato un nuovo accesso al tuo account Microsoft da un dispositivo non riconosciuto.<br><br>
      Dettagli dell'accesso:<br>
      Data e ora: ${new Date().toLocaleString()}<br>
      Posizione approssimativa: Roma, Italia<br>
      Dispositivo: Windows PC<br>
      Indirizzo IP: 93.147.xxx.xxx<br><br>
      Se sei stato tu ad effettuare questo accesso, puoi ignorare questa email.<br><br>
      Se non riconosci questa attività, ti consigliamo di verificare immediatamente la sicurezza del tuo account seguendo questi passaggi:<br>
      1. Accedi al tuo account Microsoft<br>
      2. Controlla l'attività recente<br>
      3. Se necessario, cambia la tua password<br><br>
      Puoi accedere alle impostazioni di sicurezza del tuo account qui:<br>
      <a href="#" data-fake-url="https://account.live.com/activity">Verifica attività account</a><br><br>
      Per ulteriore assistenza, visita il nostro Centro sicurezza Microsoft o contatta il supporto clienti.<br><br>
      Grazie per aver contribuito a mantenere sicuro il tuo account.<br><br>
      Team di sicurezza Microsoft
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email di sicurezza da Microsoft. Ecco perché:
      1. L'indirizzo del mittente è autentico (accountprotection.microsoft.com).
      2. L'URL del link corrisponde al dominio ufficiale di Microsoft per la gestione degli account (account.live.com).
      3. Fornisce dettagli specifici sull'accesso senza richiedere azioni immediate tramite il link.
      4. Offre passaggi per verificare la sicurezza dell'account direttamente sul sito di Microsoft.
      5. Non crea un senso di urgenza ingiustificato o minaccia la chiusura dell'account.
      6. Il linguaggio e il tono sono coerenti con le comunicazioni di sicurezza di Microsoft.
    `
  },
  {
    difficulty: "medio",
    subject: "Azione richiesta: Verifica le tue informazioni PayPal",
    sender: "service@paypal.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Come parte del nostro impegno continuo per garantire la sicurezza del tuo account PayPal, ti chiediamo gentilmente di verificare e, se necessario, aggiornare le tue informazioni personali.<br><br>
      Questo processo di verifica periodica ci aiuta a:<br>
      1. Proteggere il tuo account da accessi non autorizzati<br>
      2. Assicurare che tutte le comunicazioni importanti ti raggiungano<br>
      3. Facilitare transazioni sicure e senza problemi<br><br>
      Per completare la verifica, accedi al tuo account PayPal e segui questi passaggi:<br>
      1. Vai alla sezione "Il mio profilo"<br>
      2. Clicca su "Aggiorna" accanto a "Informazioni personali"<br>
      3. Rivedi e conferma che tutti i dettagli siano corretti<br><br>
      Puoi accedere direttamente alla pagina di verifica qui:<br>
      <a href="#" data-fake-url="https://www.paypal.com/myaccount/security/">Verifica le tue informazioni</a><br><br>
      Nota: Se tutte le tue informazioni sono già aggiornate, non è necessaria alcuna azione ulteriore.<br><br>
      Se hai domande o dubbi, non esitare a contattare il nostro servizio clienti o visitare il nostro Centro assistenza.<br><br>
      Grazie per la tua collaborazione nel mantenere la sicurezza del tuo account PayPal.<br><br>
      Il team PayPal
    `,
    isPhishing: false,
    explanation: `
      Questa è una legittima email da PayPal. Ecco perché:
      1. L'indirizzo del mittente è corretto (service@paypal.com).
      2. L'URL del link corrisponde al dominio ufficiale di PayPal (www.paypal.com).
      3. Non richiede di inserire informazioni sensibili direttamente nell'email o su un sito esterno.
      4. Fornisce istruzioni dettagliate su come aggiornare le informazioni accedendo direttamente all'account PayPal.
      5. Il linguaggio è professionale e non crea un senso di urgenza ingiustificato.
      6. Offre opzioni per contattare il servizio clienti in caso di dubbi.
      7. Le richieste periodiche di verifica delle informazioni sono pratiche comuni per i servizi finanziari online.
    `
  },  
  {
    difficulty: "difficile",
    subject: "Azione Urgente: Aggiornamento Critico di Sicurezza per il tuo Account PayPal",
    sender: "security@paypal-intl.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      In seguito a un recente aggiornamento dei nostri protocolli di sicurezza, è necessario che tutti gli utenti verifichino e aggiornino le proprie impostazioni di sicurezza con urgenza.<br><br>
      Questo processo è fondamentale per garantire la protezione continua del tuo account PayPal (${userData.email}).<br><br>
      Dettagli dell'aggiornamento:<br>
      - Implementazione di nuovi standard di crittografia<br>
      - Miglioramento dei sistemi di rilevamento delle frodi<br>
      - Aggiornamento delle politiche di autenticazione a due fattori<br><br>
      Per completare l'aggiornamento, segui questi passaggi:<br>
      1. Accedi al tuo account PayPal<br>
      2. Vai alla sezione "Sicurezza"<br>
      3. Clicca su "Completa aggiornamento di sicurezza"<br><br>
      Puoi accedere direttamente alla pagina di aggiornamento qui: <a href="#" data-fake-url="https://www.paypal.com.account-verify.net/security-update">Completa l'aggiornamento di sicurezza</a><br><br>
      Nota: Il mancato aggiornamento entro 48 ore potrebbe comportare limitazioni temporanee al tuo account per motivi di sicurezza.<br><br>
      Se hai domande o dubbi, contatta il nostro servizio clienti al numero verde indicato sul sito ufficiale di PayPal.<br><br>
      Grazie per la tua collaborazione nel mantenere PayPal un ambiente sicuro per tutti.<br><br>
      Cordiali saluti,<br>
      Team di Sicurezza PayPal
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing molto sofisticata. Ecco i segnali d'allarme:
      1. L'indirizzo del mittente usa un dominio non standard per PayPal (paypal-intl.com invece di paypal.com).
      2. L'URL del link, sebbene includa 'paypal.com', in realtà reindirizza a un dominio fraudolento (account-verify.net).
      3. Crea un senso di urgenza ingiustificato.
      4. Minaccia limitazioni all'account, una tattica comune di phishing.
      5. Nonostante l'aspetto professionale e i dettagli apparentemente legittimi, PayPal non richiederebbe mai aggiornamenti di sicurezza tramite link in un'email.
    `
  },
  {
    difficulty: "difficile",
    subject: "Avviso di Sicurezza: Aggiornamento Immediato delle Credenziali Microsoft Richiesto",
    sender: "account-security@microsoft.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Il nostro sistema di monitoraggio avanzato ha rilevato un tentativo di accesso non autorizzato al tuo account Microsoft.<br><br>
      Dettagli dell'accesso sospetto:<br>
      - Data: ${new Date().toLocaleDateString()}<br>
      - Ora: ${new Date().toLocaleTimeString()}<br>
      - Posizione: Mosca, Russia<br>
      - Indirizzo IP: 185.71.xxx.xxx<br><br>
      Per garantire la sicurezza del tuo account, ti chiediamo di agire immediatamente:<br>
      1. Aggiorna la tua password<br>
      2. Attiva l'autenticazione a due fattori (se non è già attiva)<br>
      3. Rivedi le attività recenti del tuo account<br><br>
      Puoi completare queste azioni di sicurezza accedendo qui:<br>
      <a href="#" data-fake-url="https://login.microsoftonline.com.account-security.net/change-password">Aggiorna le tue credenziali Microsoft</a><br><br>
      Importante: Se non completi questo processo entro 24 ore, potremmo dover limitare temporaneamente l'accesso al tuo account per motivi di sicurezza.<br><br>
      Se hai domande o hai bisogno di assistenza, visita il nostro Centro di supporto Microsoft o contatta il servizio clienti.<br><br>
      Grazie per la tua pronta attenzione a questa questione di sicurezza.<br><br>
      Microsoft Account Security Team
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing sofisticata che imita Microsoft. Ecco perché è sospetta:
      1. Sebbene l'indirizzo del mittente sembri autentico, le aziende come Microsoft di solito usano domini specifici per la sicurezza (come accountprotection.microsoft.com).
      2. L'URL del link è fraudolento. Microsoft non utilizzerebbe mai un dominio di terze parti (account-security.net) per gestire le credenziali degli utenti.
      3. Crea un senso di urgenza e minaccia la limitazione dell'account, tattiche comuni di phishing.
      4. Fornisce dettagli specifici sull'accesso sospetto per sembrare più credibile, ma questo è un trucco comune dei phisher.
      5. Microsoft non richiederebbe mai di aggiornare le credenziali tramite un link in un'email.
    `
  },
  {
    difficulty: "difficile",
    subject: "Avviso di Sicurezza: Verifica Immediata Richiesta per il tuo Account Google",
    sender: "no-reply@accounts.google.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Abbiamo rilevato un accesso insolito al tuo account Google da un dispositivo non riconosciuto.<br><br>
      Dettagli dell'accesso:<br>
      - Data: ${new Date().toLocaleDateString()}<br>
      - Ora: ${new Date().toLocaleTimeString()}<br>
      - Località: Kiev, Ucraina<br>
      - Dispositivo: Android smartphone<br>
      - Indirizzo IP: 93.185.xxx.xxx<br><br>
      Se non riconosci questa attività, il tuo account potrebbe essere stato compromesso. Ti consigliamo di verificare immediatamente la sicurezza del tuo account seguendo questi passaggi:<br>
      1. Controlla le attività recenti del tuo account<br>
      2. Cambia la tua password<br>
      3. Attiva l'autenticazione a due fattori (se non è già attiva)<br><br>
      Puoi accedere direttamente alla pagina di verifica della sicurezza qui:<br>
      <a href="#" data-fake-url="https://myaccount.google.com.security-check.com/security-checkup">Verifica Sicurezza Account</a><br><br>
      Se sei stato tu ad effettuare l'accesso, puoi ignorare questa email. Tuttavia, ti consigliamo comunque di rivedere regolarmente le impostazioni di sicurezza del tuo account.<br><br>
      Per ulteriore assistenza, visita il Centro assistenza Google o contatta il supporto Google.<br><br>
      Cordiali saluti,<br>
      Google Security Team
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing molto convincente che imita Google. Ecco perché è fraudolenta:
      1. Sebbene l'indirizzo del mittente sembri autentico, l'URL del link contiene un dominio fraudolento (security-check.com) che imita il dominio legittimo di Google.
      2. Google non invia mai link diretti per la verifica dell'account nelle email.
      3. L'email fornisce dettagli specifici sull'accesso sospetto per sembrare più credibile, ma questo è una tattica comune di phishing.
      4. Crea un senso di urgenza, che è una tecnica spesso utilizzata nelle email di phishing.
      5. Anche se il contenuto sembra professionale e dettagliato, Google gestisce le notifiche di sicurezza principalmente attraverso notifiche in-app o SMS, non via email.
    `
  },
  {
    difficulty: "difficile",
    subject: "Azione Urgente: Conferma il Tuo Metodo di Pagamento per Evitare la Sospensione dell'Account Netflix",
    sender: "billing@netflix.com",
    content: (userData) => `
      Ciao ${userData.name},<br><br>
      Abbiamo riscontrato un problema con il tuo metodo di pagamento attuale e non siamo riusciti a processare l'ultimo addebito per il tuo abbonamento Netflix.<br><br>
      Dettagli del problema:<br>
      - Data del tentativo di addebito: ${new Date().toLocaleDateString()}<br>
      - Importo: €14,99<br>
      - Errore: Carta di credito rifiutata<br><br>
      Per evitare interruzioni del servizio e mantenere l'accesso ai tuoi contenuti preferiti, ti chiediamo gentilmente di aggiornare o confermare i tuoi dettagli di pagamento il prima possibile.<br><br>
      Puoi farlo facilmente seguendo questi passaggi:<br>
      1. Accedi al tuo account Netflix<br>
      2. Vai alla sezione "Fatturazione e Pagamenti"<br>
      3. Verifica o aggiorna il tuo metodo di pagamento<br><br>
      Per tua comodità, puoi accedere direttamente alla pagina di aggiornamento qui:<br>
      <a href="#" data-fake-url="https://www.netflix.com.billing-update.co/payment-update">Aggiorna Metodo di Pagamento</a><br><br>
      Importante: Se non aggiorni il tuo metodo di pagamento entro 48 ore, potremmo essere costretti a sospendere temporaneamente il tuo account per evitare addebiti non autorizzati.<br><br>
      Se hai domande o hai bisogno di assistenza, non esitare a contattare il nostro servizio clienti 24/7.<br><br>
      Grazie per la tua comprensione e per essere un membro della famiglia Netflix.<br><br>
      Il team Netflix
    `,
    isPhishing: true,
    explanation: `
      Questa è un'email di phishing sofisticata che imita Netflix. Ecco perché è fraudolenta:
      1. Sebbene l'indirizzo del mittente sembri autentico, l'URL del link usa un dominio fraudolento (netflix.com.billing-update.co) che imita quello di Netflix.
      2. Netflix non utilizzerebbe mai un dominio di terze parti per l'aggiornamento dei dettagli di pagamento.
      3. L'email crea un senso di urgenza e minaccia la sospensione dell'account, tattiche comuni di phishing.
      4. Fornisce dettagli specifici sul problema di fatturazione per sembrare più credibile.
      5. Netflix generalmente gestisce i problemi di pagamento attraverso notifiche in-app o sul sito web, non tramite link diretti nelle email.
    `
  },
  {
    difficulty: "difficile",
    subject: "Avviso di Sicurezza: Nuovo Accesso al tuo Account Dropbox",
    sender: "no-reply@dropboxmail.com",
    content: (userData) => `
      Caro utente Dropbox ${userData.name},<br><br>
      Abbiamo rilevato un recente accesso al tuo account Dropbox da un nuovo dispositivo:<br><br>
      Dettagli dell'accesso:<br>
      - Data: ${new Date().toLocaleDateString()}<br>
      - Ora: ${new Date().toLocaleTimeString()}<br>
      - Dispositivo: MacBook Pro<br>
      - Sistema Operativo: macOS 12.3.1<br>
      - Browser: Chrome 98.0.4758.102<br>
      - Indirizzo IP: 185.173.35.XXX<br>
      - Posizione approssimativa: Milano, Italia<br><br>
      Se riconosci questa attività, non è necessaria alcuna azione da parte tua.<br><br>
      Tuttavia, se non sei stato tu ad effettuare questo accesso, ti consigliamo di prendere immediatamente le seguenti misure di sicurezza:<br>
      1. Cambia la password del tuo account Dropbox<br>
      2. Attiva l'autenticazione a due fattori (se non è già attiva)<br>
      3. Rivedi le sessioni attive e disconnetti i dispositivi non riconosciuti<br><br>
      Puoi gestire le impostazioni di sicurezza del tuo account qui:<br>
      <a href="#" data-fake-url="https://www.dropbox.com/account/security">Impostazioni di Sicurezza Dropbox</a><br><br>
      Se hai domande o dubbi, non esitare a contattare il nostro team di supporto.<br><br>
      Cordiali saluti,<br>
      Il team di sicurezza Dropbox
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di Dropbox. Ecco perché:
      1. L'indirizzo del mittente è corretto (dropboxmail.com è un dominio ufficiale di Dropbox per le comunicazioni email).
      2. L'URL del link corrisponde al dominio ufficiale di Dropbox.
      3. L'email fornisce dettagli specifici sull'accesso senza creare un senso di urgenza ingiustificato.
      4. Non richiede azioni immediate tramite il link, ma suggerisce di verificare l'account se l'attività non è riconosciuta.
      5. Il linguaggio e il tono sono professionali e coerenti con le comunicazioni di Dropbox.
      6. Offre passaggi di sicurezza appropriati in caso di accesso non autorizzato.
    `
  },
  {
    difficulty: "difficile",
    subject: "Importante: Aggiornamento dei Termini di Servizio e della Politica sulla Privacy di LinkedIn",
    sender: "privacy-noreply@linkedin.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Ti informiamo che LinkedIn sta aggiornando i suoi Termini di Servizio e la Politica sulla Privacy per allinearsi alle nuove normative internazionali sulla protezione dei dati e per migliorare la trasparenza su come utilizziamo e proteggiamo le tue informazioni.<br><br>
      Principali modifiche:<br>
      1. Maggiore controllo sui dati personali condivisi<br>
      2. Nuove opzioni per la gestione delle preferenze pubblicitarie<br>
      3. Chiarimenti sull'utilizzo dei dati per il miglioramento dei servizi<br>
      4. Aggiornamento delle procedure per la richiesta di cancellazione dei dati<br><br>
      Ti invitiamo a rivedere attentamente le modifiche e confermare la tua accettazione entro il 15 del prossimo mese. Puoi farlo seguendo questi passaggi:<br>
      1. Accedi al tuo account LinkedIn<br>
      2. Vai alla sezione "Impostazioni e Privacy"<br>
      3. Seleziona "Revisione dei Termini di Servizio e della Politica sulla Privacy"<br>
      4. Leggi e accetta i nuovi termini<br><br>
      Puoi accedere direttamente alla pagina di revisione qui:<br>
      <a href="#" data-fake-url="https://www.linkedin.com/legal/privacy-policy">Rivedi e Accetta i Nuovi Termini</a><br><br>
      Importante: Se non accetti i nuovi termini entro la data indicata, potresti riscontrare limitazioni nell'utilizzo di alcuni servizi LinkedIn per garantire la conformità alle nuove normative.<br><br>
      Se hai domande o desideri maggiori informazioni, ti invitiamo a visitare il nostro Centro Assistenza o a contattare il nostro team di supporto.<br><br>
      Grazie per la tua comprensione e collaborazione nel mantenere LinkedIn una piattaforma sicura e conforme per tutti i professionisti.<br><br>
      Cordiali saluti,<br>
      Il Team Legale e Privacy di LinkedIn
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di LinkedIn. Ecco perché:
      1. L'indirizzo del mittente è coerente con le comunicazioni ufficiali di LinkedIn per gli aggiornamenti sulla privacy.
      2. L'URL del link corrisponde al dominio ufficiale di LinkedIn.
      3. Il contenuto dell'email è in linea con le pratiche standard per l'aggiornamento dei termini di servizio e delle politiche sulla privacy.
      4. Fornisce dettagli specifici sulle modifiche e offre un periodo di tempo ragionevole per la revisione.
      5. Non richiede informazioni sensibili e indirizza l'utente a effettuare le modifiche all'interno della piattaforma LinkedIn.
      6. Il linguaggio è professionale e coerente con le comunicazioni di LinkedIn.
    `
  },
  {
    difficulty: "difficile",
    subject: "Azione Richiesta: Verifica e Aggiorna il Tuo Indirizzo Email di Recupero Google",
    sender: "noreply@accounts.google.com",
    content: (userData) => `
      Gentile utente Google ${userData.name},<br><br>
      Come parte del nostro impegno continuo per migliorare la sicurezza del tuo account e garantire che possiamo sempre contattarti in caso di problemi di sicurezza, ti chiediamo gentilmente di verificare o aggiornare il tuo indirizzo email secondario di recupero.<br><br>
      Stato attuale:<br>
      - Indirizzo email principale: ${userData.email}<br>
      - Indirizzo email secondario registrato: ****@${userData.email.split('@')[1]}<br><br>
      Per confermare o modificare questo indirizzo, segui questi passaggi:<br>
      1. Accedi al tuo account Google<br>
      2. Vai alla sezione "Sicurezza"<br>
      3. Trova l'opzione "Modalità per verificare che sei tu"<br>
      4. Rivedi e aggiorna l'email di recupero se necessario<br><br>
      Puoi accedere direttamente alle impostazioni di sicurezza qui:<br>
      <a href="#" data-fake-url="https://myaccount.google.com/security">Verifica Indirizzo Email di Recupero</a><br><br>
      Importante: Se non completi questa verifica entro 14 giorni, potremmo dover rimuovere l'indirizzo email secondario dal tuo account per motivi di sicurezza. Questo potrebbe influire sulla tua capacità di recuperare l'accesso al tuo account in caso di problemi.<br><br>
      Se hai domande o hai bisogno di assistenza, visita il Centro assistenza Google o contattaci direttamente.<br><br>
      Grazie per il tuo impegno nel mantenere sicuro il tuo account Google.<br><br>
      Il team di sicurezza di Google
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di Google. Ecco perché:
      1. L'indirizzo del mittente è corretto (noreply@accounts.google.com è un indirizzo ufficiale di Google per le comunicazioni relative agli account).
      2. L'URL del link corrisponde al dominio ufficiale di Google per la gestione degli account (myaccount.google.com).
      3. Il contenuto è coerente con le pratiche di sicurezza di Google.
      4. L'email non crea un senso di urgenza eccessivo e fornisce un periodo di tempo ragionevole per l'azione richiesta.
      5. Non richiede informazioni sensibili via email e indirizza l'utente a effettuare le modifiche all'interno della piattaforma Google.
      6. Fornisce dettagli parziali dell'email di recupero attuale per la verifica, una pratica comune per la sicurezza.
    `
  },
  {
    difficulty: "difficile",
    subject: "Avviso di Sicurezza: Modifica Rilevata nelle Impostazioni del Tuo Account Chase",
    sender: "secure.alerts@chase.com",
    content: (userData) => `
      Gentile cliente Chase ${userData.name},<br><br>
      Abbiamo rilevato una modifica recente alle impostazioni del tuo account online Chase che richiede la tua attenzione immediata.<br><br>
      Dettagli della modifica:<br>
      - Tipo: Aggiornamento dell'indirizzo email<br>
      - Data: ${new Date().toLocaleDateString()}<br>
      - Ora: ${new Date().toLocaleTimeString()}<br>
      - Indirizzo IP: 192.168.xxx.xxx<br><br>
      Se hai apportato tu questa modifica, puoi ignorare questa email.<br><br>
      In caso contrario, ti preghiamo di accedere immediatamente al tuo account e verificare le tue impostazioni di sicurezza seguendo questi passaggi:<br>
      1. Vai su Chase.com e accedi al tuo account<br>
      2. Seleziona "Profilo e impostazioni"<br>
      3. Rivedi le informazioni di contatto e le impostazioni di sicurezza<br>
      4. Se noti attività sospette, utilizza l'opzione "Segnala attività fraudolenta"<br><br>
      Per accedere direttamente alla pagina di verifica delle impostazioni dell'account:<br>
      <a href="#" data-fake-url="https://secure07a.chase.com/web/auth/dashboard#/dashboard/overviewAccounts/index">Verifica Impostazioni Account</a><br><br>
      Per la tua sicurezza, ti ricordiamo di:<br>
      - Non cliccare mai su link sospetti nelle email<br>
      - Accedere sempre direttamente al sito di Chase tramite il tuo browser<br>
      - Mantenere aggiornato il software antivirus sul tuo dispositivo<br><br>
      Se sospetti che il tuo account sia stato compromesso, contatta immediatamente il nostro servizio clienti al numero verde 1-800-935-9935.<br><br>
      Chase Bank - Servizio di Sicurezza Online
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di Chase Bank. Ecco perché:
      1. L'indirizzo del mittente (secure.alerts@chase.com) è un dominio ufficiale di Chase per le comunicazioni di sicurezza.
      2. L'URL del link corrisponde al sito sicuro di Chase (secure07a.chase.com).
      3. L'email fornisce dettagli specifici sulla modifica effettuata senza rivelare informazioni sensibili.
      4. Include un promemoria di sicurezza appropriato e fornisce il numero ufficiale del servizio clienti.
      5. Non richiede informazioni sensibili via email e indirizza l'utente a effettuare le verifiche direttamente sul sito di Chase.
      6. Il linguaggio e il tono sono professionali e coerenti con le comunicazioni bancarie.
    `
  },
  {
    difficulty: "difficile",
    subject: "Notifica di Spedizione FedEx: Pacco in Attesa di Istruzioni di Consegna",
    sender: "tracking.updates@fedex.com",
    content: (userData) => `
      Gentile ${userData.name},<br><br>
      Ti informiamo che il tuo pacco è arrivato presso il nostro centro di smistamento, ma non siamo riusciti a completare la consegna a causa di un indirizzo incompleto o non valido.<br><br>
      Dettagli della spedizione:<br>
      - Numero di tracking: FDX-${Math.random().toString(36).substr(2, 9).toUpperCase()}<br>
      - Data di arrivo al centro: ${new Date().toLocaleDateString()}<br>
      - Stato attuale: In attesa di istruzioni di consegna<br>
      - Mittente: ElettroShop Italia<br><br>
      Per garantire la consegna del tuo pacco, ti preghiamo di scegliere una delle seguenti opzioni entro 5 giorni lavorativi:<br>
      1. Aggiorna l'indirizzo di consegna<br>
      2. Richiedi il ritiro presso un punto FedEx a te comodo<br>
      3. Autorizza la consegna senza firma (a tuo rischio)<br>
      4. Richiedi il reso al mittente<br><br>
      Per selezionare un'opzione e fornire le istruzioni necessarie, visita il nostro portale di gestione spedizioni:<br>
      <a href="#" data-fake-url="https://www.fedex.com/apps/fedextrack/?action=althld&tracknumbers=FDX-${Math.random().toString(36).substr(2, 9).toUpperCase()}&locale=it_IT&cntry_code=it">Gestisci la tua consegna</a><br><br>
      Importante: Se non riceveremo istruzioni entro 5 giorni lavorativi, il pacco verrà restituito al mittente come da nostra politica standard.<br><br>
      Per assistenza, puoi contattare il nostro servizio clienti al numero 199.151.119 (tariffa standard) o visitare il nostro sito web www.fedex.com/it.<br><br>
      Grazie per aver scelto FedEx per le tue spedizioni.<br><br>
      Cordiali saluti,<br>
      FedEx Customer Service
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di FedEx. Ecco perché:
      1. L'indirizzo del mittente è corretto e coerente con le comunicazioni di tracking di FedEx.
      2. L'URL del link corrisponde al dominio ufficiale di FedEx per il tracciamento delle spedizioni.
      3. Le informazioni fornite sono tipiche di un avviso di consegna non riuscita.
      4. L'email offre opzioni realistiche per la gestione della consegna.
      5. Non crea un senso di urgenza eccessivo e fornisce un periodo di tempo ragionevole per l'azione.
      6. Include dettagli specifici sulla spedizione senza rivelare informazioni sensibili.
      7. Fornisce un numero di assistenza clienti verificabile e il sito web ufficiale di FedEx.
    `
  },
  {
    difficulty: "difficile",
    subject: "Azione urgente richiesta: Verifica transazione internazionale sul tuo conto HSBC",
    sender: "security.alerts@hsbc.com",
    content: (userData) => `
      Gentile cliente HSBC ${userData.name},<br><br>
      Il nostro sistema di monitoraggio delle frodi ha rilevato una transazione internazionale insolita sul tuo conto che richiede una verifica immediata.<br><br>
      Dettagli della transazione:<br>
      - Importo: €7,500.00<br>
      - Valuta: EUR<br>
      - Destinazione: Dubai, Emirati Arabi Uniti<br>
      - Data: ${new Date().toLocaleDateString()}<br>
      - Ora: ${new Date().toLocaleTimeString()}<br>
      - Tipo di transazione: Bonifico internazionale<br><br>
      Per la tua sicurezza, abbiamo temporaneamente sospeso questa transazione in attesa della tua conferma.<br><br>
      Azioni richieste:<br>
      1. Accedi al tuo conto HSBC online<br>
      2. Vai alla sezione "Transazioni recenti"<br>
      3. Trova la transazione in questione<br>
      4. Seleziona "Autorizza" o "Segnala come fraudolenta"<br><br>
      Puoi accedere al tuo conto HSBC e verificare la transazione qui:<br>
      <a href="#" data-fake-url="https://www.hsbc.co.uk/ways-to-bank/online-banking/">Accedi al tuo conto HSBC</a><br><br>
      Importante: Se non riconosci questa transazione o sospetti un'attività fraudolenta, ti preghiamo di contattare immediatamente il nostro servizio antifrode al numero +44 1234 567890.<br><br>
      Ricorda:<br>
      - HSBC non ti chiederà mai di fornire i tuoi dati personali o bancari completi via email o telefono.<br>
      - Non condividere mai i tuoi codici di sicurezza, PIN o password con nessuno, nemmeno con il personale HSBC.<br>
      - Verifica sempre l'autenticità delle comunicazioni bancarie utilizzando i canali ufficiali HSBC.<br><br>
      Per ulteriori informazioni sulla sicurezza online, visita la nostra pagina dedicata alla sicurezza su www.hsbc.co.uk/security.<br><br>
      Grazie per la tua pronta attenzione a questa questione di sicurezza.<br><br>
      Cordiali saluti,<br>
      HSBC Bank - Servizio di Sicurezza Transazioni Internazionali
    `,
    isPhishing: false,
    explanation: `
      Questa è un'email legittima di HSBC. Ecco perché:
      1. L'indirizzo del mittente è coerente con le comunicazioni ufficiali di HSBC per gli avvisi di sicurezza.
      2. L'URL del link corrisponde al dominio ufficiale di HSBC per l'online banking.
      3. L'email fornisce dettagli specifici sulla transazione sospetta senza richiedere informazioni sensibili via email.
      4. Offre un processo chiaro per verificare la transazione attraverso il sito web ufficiale della banca.
      5. Fornisce un numero di telefono per contattare direttamente la banca in caso di sospetta frode.
      6. Include importanti promemoria di sicurezza, coerenti con le pratiche bancarie standard.
      7. Non crea un senso di urgenza eccessivo, pur sottolineando l'importanza di un'azione tempestiva.
      8. Il linguaggio e il tono sono professionali e coerenti con le comunicazioni bancarie di alto livello.
    `
  }
];

